import { findIndex } from 'lodash-es'

function unHideTags(ele: Element) {
  ele.addEventListener('click', (_e) => {
    if (ele.querySelector('[data-expand-tags]')) {
      const els = Array.from(ele.children)
      els.map((aTag) => {
        if (aTag.classList.contains('hidden')) {
          aTag.classList.remove('hidden')
        }
      })
      ele.querySelectorAll('[data-expand-tags]')!.forEach((y) => y.classList.add('hidden'))
    }
    ele.classList.add('tags-open')
  })
}

export function clampTags(linesToClamp = 2, moreTagStyle = '') {
  // if any tags were hidden by css max-height, append a "view more..." link
  const nodes = document.querySelectorAll('.clamp-tags')
  for (let i = 0; i < nodes.length; i++) {
    const tag = nodes[i]
    unHideTags(nodes[i])
    if (tag.scrollHeight > tag.clientHeight) {
      const anchors = tag.querySelectorAll('a')
      const index = findIndex(anchors, function (ele) {
        return ele.offsetTop > linesToClamp * 35
      })

      if (index > 0) {
        const ele = document.createElement('template')
        ele.innerHTML = `<span data-expand-tags class='tag hide ${moreTagStyle} cursor-pointer'>...</span>`
        anchors[index].parentNode?.insertBefore(ele.content, anchors[index])

        tag.classList.add('sm:-mr-4')
        tag.classList.add('md:-mr-6')

        for (let j = index; j < anchors.length; j++) {
          anchors[j].classList.add('hidden')
        }
      }
    }
  }
}
