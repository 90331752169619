import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useCartStore } from './stores/useCartStore'
import AddToWishListApp from './AddToWishListApp.vue'
import CartApp from './CartApp.vue'
import InspirationView from './InspirationView.vue'
import JsonLdApp from './JsonLdApp.vue'
import KmCartQuantity from './components/cart/KmCartQuantity.vue'
import KmIcon from './components/general/KmIcon.vue'
import MarqueeApp from './MarqueeApp.vue'
import ProductApp from './ProductApp.vue'
import ProductGallery from './ProductGallery.vue'
import RecentlyViewed from './RecentlyViewed.vue'
import RecentlyViewedButton from './components/recently_viewed/KmRecentlyViewed.vue'
import setupSwiper from '../../utils/swiper'
import WishListApp from './WishListApp.vue'
import WishListButton from './WishListButton.vue'

const pinia = createPinia()
const node = document.querySelector('#product-app')
if (node) {
  const innerContent = node.innerHTML
  const app = createApp(ProductApp, { htmlSnippets: innerContent })
  app.use(pinia)
  app.mount('#product-app')
}

let nodes = document.querySelectorAll('.minicart-view')
for (let i = 0; i < nodes.length; ++i) {
  const app = createApp(CartApp)
  app.use(pinia)
  app.mount(nodes[i])
}

if (nodes.length > 0) {
  const { fetchCart } = useCartStore()
  fetchCart()
}

if (document.querySelector('#create-wish-list')) {
  const app = createApp(WishListApp)
  app.mount('#create-wish-list')
}

if (document.querySelector('RecentlyViewed')) {
  const app = createApp(RecentlyViewed)
  app.use(pinia)
  app.mount('RecentlyViewed')
}

nodes = document.querySelectorAll('.recently-viewed-button')
for (let i = 0; i < nodes.length; ++i) {
  const app = createApp(RecentlyViewedButton)
  app.use(pinia)
  app.mount(nodes[i])
}

nodes = document.querySelectorAll('WishListButton')
for (let i = 0; i < nodes.length; i++) {
  const productId = (nodes[i] as HTMLElement).dataset.productId
  const productType = (nodes[i] as HTMLElement).dataset.productType || 'Product'
  if (productId) {
    const app = createApp(WishListButton, { productId: productId, productType: productType })
    app.component('KmIcon', KmIcon)
    app.use(pinia)
    app.mount(nodes[i])
  }
}

if (document.querySelector('#wishlist')) {
  const app = createApp(AddToWishListApp) // , { productId: productId, productType: productType })
  app.use(pinia)
  app.mount('#wishlist')
}

nodes = document.querySelectorAll('KmCartQuantity')
for (let i = 0; i < nodes.length; i++) {
  const quantity = parseInt(<string>(nodes[i] as HTMLElement).dataset.quantity)
  const stockLevel = parseInt(<string>(nodes[i] as HTMLElement).dataset.stockLevel)
  const formId = (nodes[i] as HTMLElement).dataset.formId
  const app = createApp(KmCartQuantity, { quantity: quantity, stockLevel: stockLevel, formId: formId })
  app.mount(nodes[i])
}

if (document.querySelector('#product-gallery')) {
  const app = createApp(ProductGallery)
  app.use(pinia)
  app.mount('#product-gallery')
}

if (document.querySelector('#inspiration-sets')) {
  const app = createApp(InspirationView)
  app.component('KmIcon', KmIcon)
  app.use(pinia)
  app.mount('#inspiration-sets')
}

for (const ele of document.getElementsByClassName('swiper')) {
  setupSwiper(<HTMLElement>ele)
}

nodes = document.querySelectorAll('.marquee')
for (let i = 0; i < nodes.length; i++) {
  const app = createApp(MarqueeApp)
  app.mount(nodes[i])
}

nodes = document.querySelectorAll('json-ld')
for (let i = 0; i < nodes.length; i++) {
  const app = createApp(JsonLdApp)
  app.use(pinia)
  app.mount(nodes[i])
}

const { toggleMiniCartVisibility } = useCartStore()
window.toggleMiniCartVisibility = toggleMiniCartVisibility
