import Axios from 'axios'
import type { AxiosRequestConfig, AxiosResponseHeaders } from 'axios'

export interface KmHttpConfig<D = any> {
  timeout?: number
  data?: D
  params?: any
  headers?: AxiosResponseHeaders
}

export default function useHttps() {
  // todo: pass over functions to handle the errors in a higher component

  const get = function (url: string, options: KmHttpConfig = {}) {
    const config: AxiosRequestConfig = {
      ...options
    }
    return Axios.get(url, config).then((response) => {
      if (response.status === 200) {
        return response.data
      } else {
        throw new Error(`useHttps: problem fetching data status: ${response.status}`)
      }
    })
  }

  const post = function (url: string, data: unknown, config: AxiosRequestConfig = {}) {
    // todo: fix any with generics
    return Axios.post(url, data, config).then((response) => {
      if (response.status === 200) {
        return response
      } else {
        throw new Error(`useHttps: problem posting data status: ${response.status}`)
      }
    })
  }

  const put = function (url: string, data: any, config: AxiosRequestConfig = {}) {
    return Axios.post(url, data, config).then((response) => {
      if (response.status === 200) {
        return response
      } else {
        throw new Error(`useHttps: problem putting data status: ${response.status}`)
      }
    })
  }

  const deleteItem = function (url: string) {
    return Axios.delete(url).then((response) => response.data)
  }

  return {
    get,
    post,
    put,
    deleteItem
  }
}
