import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_KmIcon = _resolveComponent("KmIcon")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: $setup.addToCartLink(),
        class: "button button-primary button-short mb-2"
      }, "Add all to basket", 8, _hoisted_2),
      _createElementVNode("div", {
        class: "flex mb-2 items-center typeset-8",
        title: `Set ${$setup.currentIndex}`
      }, [
        _createElementVNode("button", {
          class: _normalizeClass([$setup.currentIndex > 0 ? '' : 'swiper-button-disabled', "swiper-button-prev"]),
          onClick: $setup.prevPage
        }, [
          _createVNode(_component_KmIcon, {
            name: "angle",
            class: "rotate-180"
          })
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass([$setup.currentIndex < $setup.inspirationSets.length ? '' : 'swiper-button-disabled', "swiper-button-next"]),
          onClick: $setup.nextPage
        }, [
          _createVNode(_component_KmIcon, { name: "angle" })
        ], 2)
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        src: $setup.currentSet.bgImageMobile,
        class: "block md:hidden",
        onClick: $setup.clickedHotspot
      }, null, 8, _hoisted_5),
      _createElementVNode("img", {
        src: $setup.currentSet.bgImageDesktop,
        class: "hidden md:block w-full",
        onClick: $setup.clickedHotspot
      }, null, 8, _hoisted_6),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.currentSet.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.itemId,
          style: _normalizeStyle($setup.hotspotStyle(item)),
          class: "hotspot-container"
        }, [
          _createVNode($setup["InspirationHotspot"], {
            item: item,
            onClickedHotspot: $setup.clickedHotspot
          }, null, 8, ["item"])
        ], 4))
      }), 128))
    ])
  ], 64))
}