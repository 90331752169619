import type { Artwork, Product } from '../../main/src/interfaces/ProductInterfaces'
import { flatten, uniq } from 'lodash-es'

export interface useGA4 {
  buildItem: (artwork: Artwork, product: Product) => Item
  trackAddToWishlistGA4: (productId: string) => any
}

interface Payload {
  event: string
  ecommerce: {
    items: Item[]
  }
}

interface Item {
  item_id: string | number
  item_name: string | null
  currency: string
  item_brand: string | undefined
  item_category: string | undefined
  item_category2: string | undefined
  item_category3: string | undefined
  item_category4: string | undefined
  item_category5: string | undefined
  item_variant: string
  price: number
  quantity: number
}

export default function useGA4() {
  function buildItem(artwork: Artwork, product?: Product) {
    const item_categories = uniq(flatten(artwork.categories))
    product = product || artwork.default_products![0]

    return {
      item_id: artwork.id,
      item_name: artwork.title,
      currency: 'GBP',
      item_brand: artwork.artist.slug,
      item_category: item_categories.shift(),
      item_category2: item_categories.shift(),
      item_category3: item_categories.shift(),
      item_category4: item_categories.shift(),
      item_category5: item_categories.shift(),
      item_variant: `tier_${artwork.retail_tier_id}`,
      price: getPrice(product.price),
      quantity: product.quantity
    }
  }

  function trackAddToWishlistGA4(productId: string) {
    const payload = {
      event: 'add_to_wishlist',
      ecommerce: {
        items: [
          {
            item_id: productId.split('::')[0],
            quantity: 1
          }
        ]
      }
    }

    window.dataLayer.push(payload)
  }

  function trackSearchGA4(searchTerm: string) {
    const payload = {
      event: 'search',
      search_term: searchTerm
    }
    window.dataLayer.push(payload)
  }

  function trackEventGA4(eventName: string, artwork: Artwork): void {
    const payload: Payload = {
      event: eventName,
      ecommerce: {
        items: [buildItem(artwork)]
      }
    }

    window.dataLayer.push(payload)
  }

  function getPrice(price: number | undefined): number {
    return price! / 100.0
  }

  // Sends events via the datalayer
  function sendCustomEvent(args: object) {
    const { category, action, label, value } = args
    try {
      window.dataLayer.push({
        event: 'custom_event',
        event_category: category,
        event_action: action,
        event_label: label,
        event_value: value
      })
    } catch (_e) {
      // ignore error
    }
  }

  return {
    sendCustomEvent,
    trackAddToWishlistGA4,
    trackEventGA4,
    trackSearchGA4
  }
}
