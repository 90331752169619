import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "col-span-2" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col-span-3" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "col-span-5" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "hidden md:flex gap-2 items-center mb-2" }
const _hoisted_10 = { class: "flex md:hidden gap-2 items-center mb-2" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["hotspot", { 'hotspot-close': $setup.item.visible }]),
      title: $setup.item.itemId.toString(),
      onClick: $setup.onClickedHotspot,
      onContextmenu: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.item.contextMenuVisible = !$setup.item.contextMenuVisible), ["prevent"]))
    }, null, 42, _hoisted_1),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass([$setup.hotspotDetailsClass(), "hotspot-details grid gap-4"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", {
          href: $setup.item.url,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            src: $setup.item.image
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: $setup.item.artistUrl,
            class: "underscore-hover",
            target: "_blank"
          }, _toDisplayString($setup.item.artistName), 9, _hoisted_6)
        ]),
        _createElementVNode("div", null, _toDisplayString($setup.item.printTitle), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("a", {
          href: $setup.item.url,
          class: "button button-primary w-full whitespace-nowrap",
          target: "_blank"
        }, "View print", 8, _hoisted_8)
      ])
    ], 2), [
      [_vShow, $setup.item.visible]
    ]),
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "pulse" }, null, -1)),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass([$setup.hotspotDetailsClass(), "hotspot-details"])
    }, [
      _createElementVNode("div", _hoisted_9, [
        _cache[5] || (_cache[5] = _createTextVNode(" x: ")),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.item.coords.desktop.x) = $event)),
          type: "number",
          min: "0",
          max: "100",
          maxlen: "3"
        }, null, 512), [
          [
            _vModelText,
            $setup.item.coords.desktop.x,
            void 0,
            { number: true }
          ]
        ]),
        _cache[6] || (_cache[6] = _createTextVNode(" y: ")),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.item.coords.desktop.y) = $event)),
          type: "number",
          min: "0",
          max: "100",
          maxlen: "3"
        }, null, 512), [
          [
            _vModelText,
            $setup.item.coords.desktop.y,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[7] || (_cache[7] = _createTextVNode(" x: ")),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.item.coords.mobile.x) = $event)),
          type: "number",
          min: "0",
          max: "100",
          maxlen: "3"
        }, null, 512), [
          [
            _vModelText,
            $setup.item.coords.mobile.x,
            void 0,
            { number: true }
          ]
        ]),
        _cache[8] || (_cache[8] = _createTextVNode(" y: ")),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.item.coords.mobile.y) = $event)),
          type: "number",
          min: "0",
          max: "100",
          maxlen: "3"
        }, null, 512), [
          [
            _vModelText,
            $setup.item.coords.mobile.y,
            void 0,
            { number: true }
          ]
        ])
      ]),
      _createElementVNode("button", {
        ref: "saveCoordsButton",
        class: "button w-full",
        onClick: $setup.clickSaveCoords
      }, "Save", 512)
    ], 2), [
      [_vShow, $setup.item.contextMenuVisible && $setup.profile.internal]
    ])
  ], 64))
}